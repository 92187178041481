import React from 'react';
import * as footerCss from './modules/footer.module.scss';

const Footer = () => {
    return (
        <footer className={footerCss.footer}>
            <div className={footerCss.container}>
                <p className={footerCss.title}><span className={footerCss.association}>Association -</span> Galerie Alfred Grévin</p>
                <p className={footerCss.horaire}> Tel: 06 51 01 58 79 </p>
                {/* <p className={footerCss.horaire}> 
                    Ouvert le samedi de 10h à 12h puis de 14h30 à 18h30 <br/>
                    Ainsi que sur rendez-vous. <br/>
                </p> */}
                <p className={footerCss.adresse}>33 rue de l’hopital 89700 Tonnerre (Bourgogne)</p>
            </div>
        </footer>
    )
}

export default Footer;