import React from 'react';
import * as headerCss from './modules/header.module.scss';
import { Link } from 'gatsby';
import Logo from '../images/logo.png';

const Header = () => {
    return (
        <header className={headerCss.header}>
            <Link to="/"><img src={Logo} className={headerCss.logo} alt="logo Alfred Grevin" /></Link>

            <input id="nav" className={headerCss.checkbox} name="nav" type="checkbox"/>
            <label htmlFor="nav" className={headerCss.label}></label>
            <ul className={headerCss.navList}>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/">Accueil</Link></li>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/grevin">Alfred Grevin</Link></li>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/renseignement">Renseignements</Link></li>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/exposition-encours">
                    Expositions</Link>
                    <ul>
                        <li>
                            <Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/exposition-encours">
                                En cours
                            </Link>
                        </li>
                        <li>
                            <Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/exposition">
                                Passées
                            </Link>
                        </li>
                    </ul>
                </li>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/collection">La Collection</Link></li>
                <li><Link className={headerCss.navItem} activeClassName={headerCss.activeNavItem} to="/vendre">Dessins à Vendre</Link></li>
            </ul>
        </header>
    )
}

export default Header;
