import React from 'react';
import Header from './header';
import Footer from './footer';
import '../styles/index.scss';
import * as layoutCss from './modules/layout.module.scss'
import { Helmet } from "react-helmet"

const layout = (props) => {
    return (
        <div className={layoutCss.container}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="author" content="Theodore Lefevre"/>
                <meta name="description" content="La galerie Alfred Grevin présentera des dessins originaux d’illustrateurs des 19e et 20e
                    siècle et aussi des planches originales de bandes dessinées des plus grands créateurs"/>
                <title>Galerie Alfred Grévin</title>
            </Helmet>

            <div className={layoutCss.content}>
                <Header/>
                {props.children}
            </div>
            <Footer/>
        </div>
    )
}

export default layout;
